import { SurveyChapterInsight } from "../../types/workplaceCulture";

export const storyInsightsWidgetData: SurveyChapterInsight[] = [
  {
    id: "1",
    dispatch_id: "1",
    chapter_id: "1",
    insight:
      "12% of respondents have experienced toxic environment in the past 12 months, compared to 16% in the Alda Benchmarking Database",
    order: 1,
    percentage: 12,
    benchmark_percentage: 16,
  },
  {
    id: "2",
    dispatch_id: "1",
    chapter_id: "1",
    insight:
      "24% of respondents have had trouble with maintaining work life balance in the past 12 months, compared to 25% in the Alda Benchmarking Database",
    order: 2,
    percentage: 24,
    benchmark_percentage: 25,
  },
];

export const mockInsightsWidgetData: SurveyChapterInsight[] = [
  {
    id: "1",
    dispatch_id: "1",
    chapter_id: "S.CHA.003",
    insight:
      "65% of respondents feel that leaders and managers are committed to creating an inclusive workplace, compared to 69% in the Alda Benchmarking Database",
    order: 1,
    percentage: 65,
    benchmark_percentage: 69,
  },
  {
    id: "2",
    dispatch_id: "1",
    chapter_id: "S.CHA.002",
    insight:
      "48% of respondents feel that leadership/management supports their career growth and development very or fairly well, compared to 74% in the Alda Benchmarking Database",
    order: 2,
    percentage: 48,
    benchmark_percentage: 74,
  },
];
