import { Skeleton } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { coalColor } from "../../styles/theme";
import { SurveyChapterInsight } from "../../types/workplaceCulture";
import { highlightPercentages } from "../../utils/formatters";
import { Paper } from "../ui/Paper/Paper";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./InsightsCard.scss";

export interface InsightsCardProps {
  error?: boolean;
  insights: SurveyChapterInsight[];
  isLoading?: boolean;
  surveyClosed?: boolean;
}

export const InsightsCard = ({
  error,
  insights,
  isLoading,
  surveyClosed = false,
}: InsightsCardProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  return (
    <Paper className="InsightsCard">
      <Typography
        tagVariant="p"
        desktop="body1"
        weight="bold"
        className="InsightsCard__title"
      >
        {t("insights.title")}
      </Typography>
      <div className="InsightsCard__list">
        {isLoading && !insights?.length && <Skeleton height={50} />}
        {error && (
          <Typography tagVariant="p" desktop="body2">
            {t("insights.noInsights.error")}
          </Typography>
        )}
        {!isLoading && (!insights || insights.length === 0) && (
          <Typography tagVariant="p" desktop="body2">
            {surveyClosed
              ? t("insights.noInsights.notEnoughData")
              : t("insights.noInsights.notAvailable")}
          </Typography>
        )}
        {insights?.length > 0 &&
          insights.map((insight) => {
            const insightText =
              insight.insight +
              " " +
              t("insights.benchmark", { value: insight.benchmark_percentage });
            console.log(insight.benchmark_percentage);
            return (
              <div className="InsightsCard__list__item" key={insight.id}>
                <Icon
                  type={IconTypes.Message}
                  color={coalColor}
                  size={24}
                  className="InsightsCard__list__item__icon"
                />
                <Typography tagVariant="p" desktop="body1">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: highlightPercentages(
                        insightText,
                        "InsightsCard__list__item__accent"
                      ),
                    }}
                  />
                </Typography>
              </div>
            );
          })}
      </div>
    </Paper>
  );
};
