import { TFunction } from "i18next";
import TrainingModule from "../../../models/trainingModule";
import { DOWNLOAD_ALL_ASSETS_VALUE } from "../../../utils/Training.utils";
import { Button } from "../../ui/Button/Button";
import { Select, SelectItem } from "../../ui/Select/Select";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { Typography } from "../../ui/Typography/Typography";
import { IconTypes } from "../../ui/icons";
import { Icon } from "../../ui/icons/Icon";
import "./TrainingModuleOverviewFooter.scss";

interface TrainingModuleOverviewFooterProps {
  assetDownloadError: boolean;
  canDownload: boolean;
  dropdownItems: SelectItem[] | undefined;
  handleAssetSelect: (event: any) => void;
  refetchTrainingModule: () => void;
  t: TFunction<"trainingPage", undefined>;
  trainingModule: TrainingModule;
}

export const TrainingModuleOverviewFooter = ({
  assetDownloadError,
  canDownload,
  dropdownItems,
  handleAssetSelect,
  refetchTrainingModule,
  t,
  trainingModule,
}: TrainingModuleOverviewFooterProps) => (
  <div className="TrainingModuleOverviewFooter">
    {canDownload && (
      <div className="TrainingModuleOverviewFooter__assets">
        <div className="TrainingModuleOverviewFooter__assets__dropdown">
          <Tooltip
            title="Supporting material"
            cardContent={
              <div>
                <Typography desktop="body1" mobile="body2">
                  {t("modules.assets.infoTitle")}
                </Typography>
                <Typography desktop="body1" color="secondary">
                  {t("modules.assets.infoText")}
                </Typography>
              </div>
            }
            arrow
            placement="left"
          >
            <span className="TrainingModuleOverviewFooter__icon">
              <Icon type={IconTypes.Help} size={20} />
            </span>
          </Tooltip>
          <Select
            className="TrainingModuleOverviewFooter__assets__select"
            icon={IconTypes.Download}
            items={dropdownItems}
            itemClassName={(value) =>
              value === DOWNLOAD_ALL_ASSETS_VALUE ? "Select__special" : ""
            }
            label={t("modules.assets.downloadAssets")}
            onChange={handleAssetSelect}
            value=""
          />
        </div>
        {!!assetDownloadError && (
          <Typography color="error" desktop="caption">
            {t("modules.download.downloadError")}
          </Typography>
        )}
      </div>
    )}
    <div className="TrainingModuleOverviewFooter__module">
      {!trainingModule.canDownload && (
        <Typography
          color="error"
          desktop="caption"
          className="TrainingModuleOverviewFooter__module__disabled"
        >
          {t("modules.download.downloadDisabled")}
        </Typography>
      )}
      <div className="TrainingModuleOverviewFooter__module__download">
        <Button
          className="TrainingModuleOverviewFooter__module__download__button"
          onClick={refetchTrainingModule}
          variant="contained"
          color="info"
          small
          disabled={!canDownload}
        >
          <Icon type={IconTypes.Download} color="secondary" />
          {t("modules.download.downloadAll")}
        </Button>
        <Tooltip
          title="Supporting material"
          cardContent={
            <div>
              <Typography desktop="body1" mobile="body2">
                {t("modules.download.infoTitle")}
              </Typography>
              <Typography desktop="body1" color="secondary">
                {t("modules.download.infoText")}
              </Typography>
            </div>
          }
          arrow
          placement="right"
        >
          <span className="TrainingModuleOverview__footer__icon">
            <Icon type={IconTypes.Help} size={20} />
          </span>
        </Tooltip>
      </div>
    </div>
  </div>
);
