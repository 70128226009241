import classnames from "classnames";
import { useAssetHandler } from "../../hooks/useAssetHandler";
import useTranslation from "../../hooks/useTranslation";
import TrainingModule from "../../models/trainingModule";
import { getDropdownItems } from "../../utils/Training.utils";
import { TrainingModuleContent } from "./TrainingModuleContent/TrainingModuleContent";
import { TrainingModuleOverviewFooter } from "./TrainingModuleOverviewFooter/TrainingModuleOverviewFooter";
import "./TrainingModuleOverview.scss";

interface TrainingModuleOverviewProps {
  trainingModule: TrainingModule;
}

export const TrainingModuleOverview = ({
  trainingModule,
}: TrainingModuleOverviewProps) => {
  const { i18n, t } = useTranslation("trainingPage");
  const { handleAssetSelect, assetDownloadError } = useAssetHandler(
    trainingModule.referenceId
  );
  const dropdownItems = getDropdownItems(trainingModule.assets, t);

  const capitalClasses = classnames("TrainingModuleOverviewContent__title", {
    "TrainingModuleOverviewContent__title--capitalize":
      i18n.language !== "is-IS",
  });

  return (
    <div className="TrainingModuleOverview">
      <div className="TrainingModuleOverview__header">
        <img
          className="TrainingModuleOverview__header__thumbnail"
          src={trainingModule.gradient}
          alt=""
        />
      </div>
      <TrainingModuleContent
        capitalClasses={capitalClasses}
        t={t}
        trainingModule={trainingModule}
      />
      <TrainingModuleOverviewFooter
        assetDownloadError={assetDownloadError}
        canDownload={trainingModule.canDownload}
        dropdownItems={dropdownItems}
        handleAssetSelect={handleAssetSelect}
        refetchTrainingModule={() => {}}
        t={t}
        trainingModule={trainingModule}
      />
    </div>
  );
};
